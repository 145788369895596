
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import { Tabs } from '../../../utilities/Types/navigation.types';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import { checkPermission } from '../../../utilities/Permissions/permission-utillty';
import { AccessTypes } from '../../../enums/AccessTypes';
import axios from 'axios';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted() {
    await this.resetPaginationState();
    console.log('THIS USER: ', this.user);
    if (this.user) {
      console.log('IN MOUNTED');
      const response = await this.fetchMallAdminMarketingCampaignsReports();
      this.total = response?.total;
    }
  },
  watch: {
    loadingMallAdminMarketingCampaignsReport: {
      handler() {
        this.loading = this.loadingMallAdminMarketingCampaignsReport;
      },
    },
    user: {
      async handler() {
        const response = await this.fetchMallAdminMarketingCampaignsReports();
        this.total = response?.total;
      },
    },
    pageValue: {
      deep: true,
      async handler() {
        await this.updatePaginationPage(this.pageValue.value as number);
        if (!this.pageReset) {
          const response = await this.fetchMallAdminMarketingCampaignsReports();
          this.total = response?.total;
        }
        this.pageReset = false;
      },
    },
    showValue: {
      deep: true,
      async handler() {
        await this.updatePaginationLimit(this.showValue.value as number);
        const response = await this.fetchMallAdminMarketingCampaignsReports();
        this.total = response?.total;
      },
    },
    searchFilter: {
      deep: true,
      async handler() {
        await this.updatePaginationSearch(this.searchFilter);
        const response = await this.fetchMallAdminMarketingCampaignsReports();
        this.total = response?.total;
      },
    },
    sortDefinition: {
      deep: true,
      async handler() {
        await this.updatePaginationSortKey(this.sortDefinition.column);
        await this.updatePaginationSortDirection(this.sortDefinition.direction);
        const response = await this.fetchMallAdminMarketingCampaignsReports();
        this.total = response?.total;
      },
    },
    showClosedStores: {
      deep: true,
      async handler() {
        await this.updatePaginationClosedStores(this.showClosedStores);
        const response = await this.fetchMallAdminMarketingCampaignsReports();
        this.total = response?.total;
      },
    },
  },
  computed: {
    ...mapGetters(['user', 'access', 'permissions', 'mallAdminMarketingCampaignsReport', 'loadingMallAdminMarketingCampaignsReport']),
    columnDefinitions(): ColumnDefinition[] {
      return [
        {
          type: 'text',
          columnName: 'companyDesc',
          displayName: 'Company',
        },
        {
          type: 'text',
          columnName: 'brandDesc',
          displayName: 'Brand',
          headerStyle: 'min-width: 140px',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'mallDesc',
          displayName: 'Mall',
          headerStyle: 'min-width: 140px',
        },
        {
          type: 'text',
          columnName: 'statusDesc',
          displayName: 'Store Status',
        },
        {
          type: 'text',
          columnName: 'storeManagerName',
          displayName: 'Store Contact Name',
          headerStyle: 'min-width: 125px',
        },
        {
          type: 'text',
          columnName: 'storeManagerEmail',
          displayName: 'Store Contact Email',
          headerStyle: 'min-width: 125px',
        },
        {
          type: 'text',
          columnName: 'draftCount',
          displayName: '# of Draft Campaigns',
          position: 'center',
        },
        {
          type: 'text',
          columnName: 'activeUpcomingCount',
          displayName: '# of Active/Upcoming Campaigns',
          position: 'center',
        },
        {
          type: 'date',
          columnName: 'lastCampaignSubmittedDate',
          displayName: 'Last Campaign Submitted',
          position: 'center',
        },
        {
          type: 'date',
          columnName: 'latestCampaignEndDate',
          displayName: 'Last Campaign End Date',
          position: 'center',
        },
        {
          type: 'text',
          columnName: 'averageCampaignCreationFrequency',
          displayName: 'Avg. Campaign Creation Frequency',
          position: 'center',
        },
        {
          type: 'text',
          columnName: 'daysOutstandingWithoutCampaign',
          displayName: 'Days Outstanding Without Campaign',
          position: 'center',
        },
      ];
    },
    tableData(): unknown {
      return this.mallAdminMarketingCampaignsReport;
    },
    tableOptions(): any {
      return {
        clickable: false,
      };
    },
    tabs(): Tabs {
      const t: any[] = [];
      if (this.access && !this.access?.marketingCampaignsNone) {
        t.push({
          title: 'MARKETING CAMPAIGNS',
          route: 'marketing-campaigns',
          activeRouteCheck: '/mall-admin-reports/marketing-campaigns',
        });
      }
      if (this.access && !this.access?.recruitmentCampaignsNone) {
        t.push({
          title: 'RECRUITMENT CAMPAIGNS',
          route: 'recruitment-campaigns',
          activeRouteCheck: '/mall-admin-reports/recruitment-campaigns',
        });
      }
      if (this.access?.marketingCampaignsNone) {
        t.push({
          title: 'MARKETING CAMPAIGNS',
          route: 'marketing-campaigns',
          activeRouteCheck: '/mall-admin-reports/marketing-campaigns',
        });
      }
      if (this.access?.recruitmentCampaignsNone) {
        t.push({
          title: 'RECRUITMENT CAMPAIGNS',
          route: 'recruitment-campaigns',
          activeRouteCheck: '/mall-admin-reports/recruitment-campaigns',
        });
      }
      return t;
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    pageReset: boolean;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
    showClosedStores: boolean;
    hideMC: boolean;
    hideRC: boolean;
    hideMM: boolean;
    hideSM: boolean;
  } {
    return {
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      pageReset: false,
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      total: 0,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
      showClosedStores: false,
      hideMC: true,
      hideRC: true,
      hideMM: true,
      hideSM: true,
    };
  },

  methods: {
    ...mapActions([
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'fetchMallAdminMarketingCampaignsReports',
      'updatePaginationPage',
      'updatePaginationLimit',
      'updatePaginationSearch',
      'updatePaginationSortKey',
      'updatePaginationSortDirection',
      'updatePaginationClosedStores',
      'resetPaginationState',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageReset = true;
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    async checkAccess() {
      this.hideMC = await checkPermission('access', this.user, AccessTypes['marketingCampaignsNone']);
      this.hideRC = await checkPermission('access', this.user, AccessTypes['recruitmentCampaignsNone']);
      this.hideMM = await checkPermission('access', this.user, AccessTypes['mallMemosNone']);
      this.hideSM = await checkPermission('access', this.user, AccessTypes['storeMemosNone']);
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    async search(searchFilter: string): Promise<void> {
      this.resetPageValue();
      this.searchFilter = searchFilter;
    },
    async export(): Promise<void> {
      this.buttonFeedback.exportSubmitted = true;
      this.buttonFeedback.exportLoading = false;
      const URL = `${process.env.VUE_APP_URI}/exports/mall-admin-marketing-campaign-report`;
      this.$notify({
        title: 'Export is in progress.',
        text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
        type: 'info',
        duration: 12000,
      });
      setTimeout(() => {
        this.buttonFeedback.exportSubmitted = false;
      }, 12000);
      await axios.get(URL, {
        withCredentials: true,
      });
    },
    approvalClicked(payload: { status: string; type: string; row: any }): void {
      payload.row.commType = 'marketing-campaign';
      this.updateModalComponent('approval-reports');
      this.updateModalType('large');
      this.updateModalData(payload);
      this.openModal();
    },
    async toggleClosedStores(checked: any) {
      this.showClosedStores = checked;
    },
  },
});
